<template>
  <div>
    <!-- 管理员主页 -->
    <Charts />
  </div>
</template>

<script>
//import Edit from "../../../components/ControlPanel/edit";
import Charts from "../../../components/ControlPanel/Charts";
export default {
  //inject:["reload"],
  components: {
    // Edit,
    Charts,
  },
  mounted() {
    //this.reload();
  },
  methods: {},
};
</script>

<style></style>
